import React from "react";
import { graphql } from "gatsby";
import { getCaseStudyPageProps } from "helpers/getCaseStudyPageProps";
import LetsTalkComponent from "components/letsTalkComponent";
import CaseStudyTop from "components/caseStudyTop";
import FilterProject from "components/filterProject";
import Seo from "components/seo";
import Layout from "components/layout";

const CaseStudies = ({ data }) => {
  const { seo, topPart, types, projects, letsTalk, filterArrow } =
    getCaseStudyPageProps(data);
  return (
    <Layout>
      <Seo {...seo} />
      <CaseStudyTop {...topPart} />
      <FilterProject
        types={types}
        projects={projects}
        filterArrow={filterArrow}
      />
      <LetsTalkComponent {...letsTalk} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyPageData {
    strapiCaseStudy {
      seo {
        metaTitle
        metaDescription
        shareImage {
          alternativeText
          localFile {
            url
          }
        }
      }
      topPart {
        titlePartOne
        titlePartTwo
        stat {
          number
          name
          order
        }
      }
      types {
        name
        order
      }
      filterArrow {
        alternativeText
        localFile {
          url
        }
      }
      projects {
        projects {
          projectTitle
          slug
          type
          shortDescription {
            data {
              shortDescription
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      letsTalk {
        title
        subtitle
        btn
        bgDark
        address
        btnArrow {
          alternativeText
          localFile {
            url
          }
        }
        preezmaLogo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default CaseStudies;
