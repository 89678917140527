import React from "react";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import * as styles from "./caseStudyTop.module.scss";

const CaseStudyTop = ({ titlePartOne, titlePartTwo, stat }) => {
  const statData = sortArrayOfObjects(
    stat.map(({ name, number }, i) => {
      return (
        <div key={i} className="mb-40">
          <p className={`${styles.num} mb-15`}>{number}</p>
          <p className={styles.name}>{name}</p>
        </div>
      );
    })
  );
  return (
    <div
      className={`${styles.top} d-flex flex-md-row flex-column justify-content-between align-items-center`}
    >
      <div className={styles.title}>
        <p className={styles.titleOne}> {titlePartOne}</p>
        <p className={styles.titleTwo}>{titlePartTwo}</p>
      </div>
      <div className={`${styles.stat} pv-50 ph-70`}>{statData}</div>
    </div>
  );
};

export default CaseStudyTop;
