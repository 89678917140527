import React, { useState, useEffect } from "react";
import { sortArrayOfObjects } from "helpers/sortArrayOfObjects";
import { getStaticImgProps } from "helpers/getImgProps";
import SingleProject from "components/singleProject";
import * as styles from "./filterProject.module.scss";

const FilterProject = ({ types, projects: { projects }, filterArrow }) => {
  const [rotated, setRotated] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  useEffect(() => {
    {
      window &&
        window.scrollTo({
          top: 0,
        });
    }

    const selected = types.map(({ name }) => name);
    setSelectedCheckboxes(selected);
  }, []);

  useEffect(() => {
    const filteredProjectsList = projects.filter((project) =>
      selectedCheckboxes.includes(project.type)
    );
    setFilteredProjects([...filteredProjectsList]);
  }, [selectedCheckboxes]);

  const handleClick = () => {
    setToggled(!toggled);
    setRotated(!rotated);
  };
  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else
      setSelectedCheckboxes(
        selectedCheckboxes.filter((type) => type !== value)
      );
  };

  const projectList = filteredProjects.map(
    ({ projectTitle, slug, shortDescription, image, type }, index) => {
      return (
        <SingleProject
          key={index}
          image={image}
          title={projectTitle}
          text={shortDescription.data.shortDescription}
          index={index}
          homepage={false}
          type={type}
          slug={slug}
        />
      );
    }
  );

  const typeList = sortArrayOfObjects(
    types.map(({ name }, i) => {
      return (
        <div
          className={`${styles.singleCheckbox} d-flex justify-content-start align-items-center`}
          key={i}
        >
          <label
            className={`${styles.container} d-flex justify-content-start align-items-center`}
          >
            <input
              type="checkbox"
              name="region"
              value={name}
              onChange={handleChange}
              className="mr-18"
              defaultChecked={true}
            />
            <span className={styles.checkmark}></span>
            {name}
          </label>
        </div>
      );
    })
  );

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.typeContainer} mb-140`}>
        <div
          className={`${styles.type} pv-30 d-lg-flex flex-lg-row justify-content-lg-statr align-items-lg-center`}
        >
          {typeList}
        </div>
        <div className={`${styles.addFilter}`}>
          <span className={styles.text}>Add filter</span>
          <span onClick={handleClick}>
            <img
              {...getStaticImgProps(filterArrow)}
              className={toggled ? styles.rotated : ""}
            />
          </span>
        </div>
        {toggled && <div className={styles.mobile}>{typeList}</div>}
      </div>
      <div>{projectList}</div>
    </div>
  );
};

export default FilterProject;
