export const getCaseStudyPageProps = ({
  strapiCaseStudy: {
    seo,
    topPart,
    types,
    projects,
    letsTalk,
    bgBlueGrey,
    filterArrow,
  },
}) => {
  return {
    seo,
    topPart,
    types,
    projects,
    letsTalk,
    bgBlueGrey,
    filterArrow,
  };
};
